import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import React from 'react';

import { BaseSanityPage, PortableTextBlocks } from 'types/schema';
import Box, { BoxProps } from 'components/Box';
import Flex from 'components/Flex';
import Grid from 'components/Grid';
import InnerWrap from 'components/layout/InnerWrap';
import LatestResources from 'components/resources/LatestResources';
import MainLayout from 'components/layout/MainLayout';
import OuterWrap from 'components/layout/OuterWrap';
import PagePictureTitle from 'components/layout/PagePictureTitle';
import PortableText from 'components/PortableText';

const SubHeading: React.FC<BoxProps> = (props) => (
  <Box as="h2" variant="header2Serif" textAlign="center" mb={6} {...props} />
);
const SubSubHeading: React.FC = (props) => (
  <Flex justifyContent="center">
    <Box as="h4" variant="header4Serif" fontStyle="italic" color="darkGold" mb={4} {...props} />
  </Flex>
);

export const pageQuery = graphql`
  query AboutPageQuery {
    carterPic: file(relativePath: { eq: "CarterPLG-vertical.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 460, layout: CONSTRAINED)
      }
    }
    sanityAboutPage(id: { eq: "-ab256ce4-4d4c-55c2-b7b7-91685e1d7a42" }) {
      meta {
        title
        description
      }
      pageTitle
      subheading1Title
      subheading1Body
      subheading2Title
      subheading2Body
      subheading3Title
      subheading3Body
      valuesSectionTitle
      value1Title
      value1Body
      value2Title
      value2Body
      value3Title
      value3Body
      bioTitle
      _rawBioBody
      membershipsTitle
      membershipsList
      educationTitle
      educationList
    }
  }
`;
type QueryData = {
  carterPic: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  sanityAboutPage: BaseSanityPage & {
    subheading1Title: string;
    subheading1Body: string;
    subheading2Title: string;
    subheading2Body: string;
    subheading3Title: string;
    subheading3Body: string;
    valuesSectionTitle: string;
    value1Title: string;
    value1Body: string;
    value2Title: string;
    value2Body: string;
    value3Title: string;
    value3Body: string;
    bioTitle: string;
    _rawBioBody: PortableTextBlocks;
    membershipsTitle: string;
    membershipsList: string[];
    educationTitle: string;
    educationList: string[];
  };
};

const AboutPage: React.FC<{ data: QueryData }> = ({ data }) => {
  const carterPicFluid = data.carterPic.childImageSharp.gatsbyImageData;
  const {
    meta: { title, description },
    pageTitle,
    subheading1Title,
    subheading1Body,
    subheading2Title,
    subheading2Body,
    subheading3Title,
    subheading3Body,
    valuesSectionTitle,
    value1Title,
    value1Body,
    value2Title,
    value2Body,
    value3Title,
    value3Body,
    bioTitle,
    _rawBioBody,
    membershipsTitle,
    membershipsList,
    educationTitle,
    educationList,
  } = data.sanityAboutPage;
  return (
    <MainLayout seoProps={{ title, description, pagePath: '/about/' }}>
      <PagePictureTitle
        title={pageTitle}
        pictureUrl="https://images.unsplash.com/photo-1496498808362-7e2ec3b323d4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1001&q=80"
        outerSx={{ backgroundPositionY: '70%' }}
        overlayProps={{ opacity: 0.6 }}
      />
      <OuterWrap>
        <InnerWrap>
          <Grid gridTemplateColumns={['1fr', '1fr 1fr 1fr']} gridGap={5}>
            <Box>
              <SubHeading>{subheading1Title}</SubHeading>
              <p>{subheading1Body}</p>
            </Box>
            <Box>
              <SubHeading>{subheading2Title}</SubHeading>
              <p>{subheading2Body}</p>
            </Box>
            <Box>
              <SubHeading>{subheading3Title}</SubHeading>
              <p>{subheading3Body}</p>
            </Box>
          </Grid>
        </InnerWrap>
      </OuterWrap>
      <OuterWrap bg="grey100">
        <InnerWrap display="flex" flexDirection={['column', 'row']} alignItems="center">
          <SubHeading flex={['0', '0 0 300px', '0 0 400px']} mb={[6, 6, 0]}>
            {valuesSectionTitle}
          </SubHeading>
          <Grid gridGap={5}>
            <Box>
              <SubSubHeading>{value1Title}</SubSubHeading>
              <Box>{value1Body}</Box>
            </Box>

            <Box>
              <SubSubHeading>{value2Title}</SubSubHeading>
              <Box>{value2Body}</Box>
            </Box>
            <Box>
              <SubSubHeading>{value3Title}</SubSubHeading>
              <Box>{value3Body}</Box>
            </Box>
          </Grid>
        </InnerWrap>
      </OuterWrap>
      <OuterWrap>
        <InnerWrap>
          <Flex justifyContent="center">
            <Box
              as="h2"
              variant="header2Serif"
              lineHeight="1.2em"
              textAlign="center"
              borderBottom="2px solid"
              borderColor="gold"
            >
              {bioTitle}
            </Box>
          </Flex>
          <Flex mt={8} alignItems={['center', 'flex-start']} flexDirection={['column', 'row']}>
            <Box maxWidth={460} width={['100%', 'auto']} flex={1}>
              <GatsbyImage image={carterPicFluid} alt="Carter G. Perks portrait" />
              <Box color="grey500" mt={1} fontSize="1.2rem">
                Credit: Robert McGee Photography
              </Box>
            </Box>
            <Box flex={1} ml={[0, 6]} sx={{ p: { mb: 4 } }}>
              <PortableText blocks={_rawBioBody} />
            </Box>
          </Flex>
          <Flex mt={6} alignItems="flex-start" flexDirection={['column', 'row']}>
            <Box flex={1}>
              <Box as="h4" variant="header4Serif">
                {membershipsTitle}:
              </Box>
              <ul>
                {membershipsList.map((mem) => (
                  <li key={mem}>{mem}</li>
                ))}
              </ul>
            </Box>
            <Box flex={1}>
              <Box as="h4" variant="header4Serif">
                {educationTitle}:
              </Box>
              <ul>
                {educationList.map((edu) => (
                  <li key={edu}>{edu}</li>
                ))}
              </ul>
            </Box>
          </Flex>
        </InnerWrap>
      </OuterWrap>
      <LatestResources wrapProps={{ bg: 'grey100' }} />
    </MainLayout>
  );
};

export default AboutPage;
